import axios from "axios";
import { Message } from 'element-ui';
import message from "element-ui/packages/message";
import router from "@/router";
import qs from 'qs'
//请求拦截器
axios.interceptors.request.use(
    //如果存在sid
    config=>{
        if (window.sessionStorage.getItem('sid')){
            config.headers['sid']=window.sessionStorage.getItem('sid');

        }
        return config
    },error => {
        console.log(error);
    }
);
//响应拦截器
axios.interceptors.response.use(success=>{
    // console.log(111)
    // if (success.config.skipInterceptor) {
    //     return success.data;
    // }
//    业务逻辑错误
    if (success.status&&success.status==200){
        if (success.data.message){
         if (success.data.code==500){
             Message.error({message:success.data.message});
            return;
         }
         if (success.data.message&&success.data.code!=200){
             Message.error({message:success.data.message});
             return;
         }
         if (success.data.code==200){
             Message.success({message:success.data.message});
         }
        }
    }
    return success.data;
},error => {
    if (error.response.code==504||error.response.code==404){
        Message.error({message:"服务器失效"});
    }else if (error.response.code==403){
        Message.error({message:"服务器失效"});
    }else if (error.response.code==401){
        Message.error({message:"请登录"});
        router.replace('/')
    }else {
        if (error.response.data.message){
            Message.error({message:error.response.data.message});
        }else {
            Message.error({message:"未知错误"})
        }
    }
    return;
});

let base = '';
 base = 'web';


export  const  postRequest= (url,params)=>{
    url = base+url
    return axios.post(url,qs.stringify(params))
}
export const postRequest3 = (url, params) => {
    url = base + url;
    return axios.post(url, qs.stringify(params), {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    });
};
export  const  postRequest2= (url,params)=>{

    url = base+url
    return axios.post(url,params)
}
export  const  putRequest = (url,params)=>{


    console.log(params)
    return axios.put(url,params)
}

export  const  getRequest = (url,params)=>{
    url = base+url
    return axios.get(url,params)
}


