<template >
  <div>
    <el-form :rules="rules"
             v-loading="loading"
             element-loading-text="拼命加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)"
             ref="loginForm" :model="loginForm"
             class="loginContainer"
             @keydown.enter.native="submitLogin"
    >
      <h3 class="loginTitle">系统登录</h3>
        <el-form-item prop="username">
            <el-input type="text" v-model="loginForm.username" aria-placeholder="请输入用户名"  placeholder="请输入用户名"   ></el-input>
        </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" v-model="loginForm.password" aria-placeholder="请输入密码" placeholder="请输入密码" ></el-input>
      </el-form-item>
      <el-button type="primary" style="width: 100%" @click="submitLogin">登录</el-button>
    </el-form>
    <div>

      <KOR></KOR>
    </div>

  </div>

</template>

<script>


import {postRequest} from "@/utils/api";
import KOR from "@/views/KeepOnRecord/KOR";

export default {
  name: "Login",
  components: {KOR},
  data(){
    return{
        loginForm:{
            username:null,
            password:null
        },
      loading:false,
        rules:{
          username:[{required:true,message:'请输入用户名',trigger:'blur'}],
          password:[{required:true,message:'请输入密码',trigger:'blur'}]
        }
    }
  },
  methods:{
    submitLogin(){
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading =true;
            postRequest('/jiehu/login/login',this.loginForm).then(resp=>{
              this.loading =false;

              if (resp){

                console.log(resp.data.sid)
                this.loading =false;
                //存储sid
                const sid = resp.data.sid;
                window.sessionStorage.setItem('sid',sid);
                let path = this.$route.query.redirect;
                this.$router.replace((path=='/'||path==undefined?'/home':path));
              }
            })
        } else {

          return false;
        }
      });
    }
  }
}
</script>

<style >
.loginContainer{
  border-radius: 15px;
  background-clip: padding-box;
  margin: 100px auto auto;
  width: 270px;
  padding: 15px 35px 15px 35px;
  background: #FFF;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

.loginTitle{
    margin: 0px auto 40px auto;
  text-align: center;
}
</style>
