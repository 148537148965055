<template>
<div>

  <div style="bottom: 0;left: 0;height: 30px;line-height: 30px;background-color: #f1f1f1;text-align: center;width: 100%">
    <div>
    <a href="https://beian.miit.gov.cn/" target="_blank"> 备案号：浙ICP备2022013839号-1</a>
    </div>
    <div style="background-color: #f1f1f1; ">
    <img src="../assets/KOR.png">
    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33078202001907" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">浙公网安备 33078202001907号</p></a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "KOR"
}
</script>

<style scoped>

  </style>
