import Vue from 'vue'
export default {
    install: function(Vue, options = {}) {
        Vue.directive('loadmore', {
            inserted: function(el, binding) {
                // 获取element-ui定义好的scroll盒子
                const SELECTDOWN_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
                SELECTDOWN_DOM.addEventListener('scroll', function() {
                    const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight;
                    if (CONDITION) {
                        binding.value();
                    }
                });
            }
        });
    }
};
