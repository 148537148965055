import Vue from 'vue'
import App from './App.vue'
import store from "./store";
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';



import {initMenu} from "@/utils/menu";
import {postRequest} from "@/utils/api";
import Router from 'vue-router';
import loadMore from './utils/select';

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


router.beforeEach((to,from,next)=>{
    if (window.sessionStorage.getItem("sid")){
        initMenu(router,store);
        console.log(window.sessionStorage.getItem("user"))
        const path = to.path;
        const name = to.meta.title || to.name; // 如果有页面名称，则使用页面名称，否则使用路由名称

        if (path && path !== '/') { // 检查路径是否为空或为根路径
            const page = { path, name };

            // 检查历史记录中是否已存在相同页面信息，避免重复记录
            if (!store.state.history.some(item => item.path === path)) {
                store.dispatch('addToHistory', page);
            }
        }
        if (!window.sessionStorage.getItem("user")){
            return postRequest("/jiehu/login/get").then(resp=>{
                if (resp){
                    window.sessionStorage.setItem("user",JSON.stringify(resp))
                    window.sessionStorage.setItem("oId",JSON.stringify(resp.data.outletsId))
                    next();
                }
            })
        }

        next();
    }else {
        if (to.path=='/'){
            next();
        }else {
            next('/?redirect='+to.path);
        }

    }

} );
// 在页面刷新时清空历史记录
window.addEventListener('beforeunload', () => {
    store.dispatch('clearHistory');
});
Vue.config.productionTip = false
Vue.use(ElementUI,{size:'small'});

Vue.use(loadMore)

new Vue({
  router,
    store,
  render: h => h(App)
}).$mount('#app')
