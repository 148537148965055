import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default  new Vuex.Store({
    state:{
        routes:[],
        history: [
            {path: '/home', name: '首页'}
        ] // 存储用户点击记录的数组
    },
    mutations:{
            initRoutes(state,data){
                console.log(data)
                state.routes =data;
                console.log(state.routes)
            },
        addHistory(state, item) {
            state.history.push(item);
        },
        removeFromHistory(state, path) {
            state.history = state.history.filter(item => item.path !== path);
        },
        clearHistory(state) {
            state.history = [
                {path: '/home', name: '首页'}
            ];
        }
    },
    actions: {

        addToHistory({ commit }, item) {
            commit('addHistory', item);
            console.log(item)
        },
        clearHistory({ commit }) {
            commit('clearHistory');
        }
    }

})