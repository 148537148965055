<template>
  <div>
    <el-container>
      <el-header class="homeHeader">

        <div class="tittle">   卡乐福管理系统
        </div>

        <el-dropdown class="userInfo" @command="commandUser">
            <span class="el-dropdown-link">
             {{user.data.username}}<i><img :src="user.data.userFace"></i>
            </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="changePassword">修改密码</el-dropdown-item>
            <el-dropdown-item command="logout">注销登录</el-dropdown-item>
          </el-dropdown-menu>

        </el-dropdown>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <el-menu router unique-opened>
          <el-submenu :index="index+''" v-for="(item,index) in routes"
                      :key="index"
                      v-if="!item.hidden">
            <template slot="title"><i class="el-icon-menu" style="color: #317f7f"></i>
              {{item.name}}</template>
            <el-menu-item-group>
              <el-menu-item :index="children.path"
                            v-for="(children,indexj) in item.children">{{children.name}}

              </el-menu-item>

            </el-menu-item-group>
          </el-submenu>
          </el-menu>
        </el-aside>
        <el-container>
        <el-main>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(item, index) in history" :key="index">
              <span @click="navigateTo(item.path)">{{ item.name }}</span>
              <button v-if="index!=0" @click="removeFromHistory(item.path)" class="delete-button">
                <i class="el-icon-delete-solid"></i>
              </button>
            </el-breadcrumb-item>
          </el-breadcrumb>
          <div class="hello" v-if="this.$router.currentRoute.path=='/home'">
            欢迎使用管理程序
          </div>
          <div>
            <el-dialog
                title="修改密码"
                :visible.sync="doPassword"
                width="30%"
            >
              <div>
                <el-tag>密码</el-tag>
                <el-input  v-model="updatePos.password" placeholder="请输入内容" class="updatePosInput">
                </el-input>
              </div>
              <span slot="footer" class="dialog-footer">
        <el-button @click="doPassword = false" size="small">取 消</el-button>
        <el-button type="primary" @click="doUpdatePassword()" size="small">确 定</el-button>
      </span>
            </el-dialog>
          </div>
          <router-view class="homeRoute"/>

        </el-main>
          <el-footer><KOR></KOR></el-footer>
        </el-container>

      </el-container>
    </el-container>


  </div>

</template>

<script>


import {postRequest} from "@/utils/api";
import KOR from "@/views/KeepOnRecord/KOR";
export default {

  name: "Home",
  components: {KOR},
  data(){
    return{
      user:JSON.parse(window.sessionStorage.getItem('user')),
      updatePos:{
        username:'',
        password:'',
      },
      doPassword:false,

    }
  },
  computed:{
    history() {
      // 假设history是从 Vuex store 中获取的用户点击记录
      return this.$store.state.history ;
    },
      routes(){

        return this.$store.state.routes;
      }
  },
  methods:{
    removeFromHistory(path) {
      this.$store.commit('removeFromHistory', path);
    },
    navigateTo(path) {
      // 导航到指定路径
      this.$router.push(path);

    },
    doUpdatePassword(){
      if (!this.updatePos.password) {
        // 如果密码字段为空，显示错误提示或者其他处理方式
        this.$message.error('密码不能为空')
        return;
      }
      postRequest('/jiehu/account/save',this.updatePos).then(resp=>{
        if (resp){
          this.doPassword=false;
        }
      })
    },
    commandUser(command){
      if (command=="logout"){

        this.$confirm('此操作将注销登录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          postRequest("jiehu/login/out");
          window.sessionStorage.removeItem("sid");
          window.sessionStorage.removeItem("user");
          this.$store.commit("initRoutes",[]);
          this.$router.replace("/");

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消注销'
          });
        });


      }


      if (command=="changePassword"){

        this.updatePos.username = this.user.data.username;
        this.updatePos.id = this.user.data.id;
        this.doPassword=true;
      }
    }
  }
}
</script>

<style >
  .homeHeader{
    background: #0886ab;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
  }
 .tittle{
     font-size: 30px;
    font-family: 华文细黑;
    color: white;
  }
 .userInfo{
   cursor: pointer;
 }
 .el-dropdown-link img{
   width: 48px;
   height: 48px;
   border-radius: 24px;
   margin-left: 8px;
 }
 .el-dropdown-link{
   color: white;
   font-family: 华文细黑;
 }
  .el-dropdown-title img{
    width: 350px;
    height: 48px;
    margin-left: 8px;
  }
 .hello{
   text-align: center;
   font-size: 30px;
   font-family: 华文细黑;
   color: #0886ab;
   padding-top: 50px;
 }
 .homeRoute{
   margin-top: 10px;
 }
</style>
