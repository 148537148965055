import {getRequest, postRequest} from "@/utils/api";


export  const  initMenu =(router,store) =>{
    if (store.state.routes.length>0){
        return;
    }
   postRequest('/jiehu/menu/get').then(
        data=>{
            if (data.data){

                let  fmtRoutes =formatRoutes(data.data);

                //添加路由
                router.addRoutes(fmtRoutes);

                //存入vuex
                store.commit('initRoutes',fmtRoutes);
            }
        }
    )
}
export  const formatRoutes = (routes) =>{
     let fmtRoutes =[];
     routes.forEach(router=>{
         let {
             path,
             component,
             name,
             children,
         }=router;
         if (children && children instanceof Array){
             children = formatRoutes(children);
         }
         let fmRouter ={
             path:path,
             name:name,
             children:children,

             component(resolve){
                 if (component.startsWith("Root")){
                     require(['../views/root/'+component+'.vue'],resolve)
                 }else if (component.startsWith("Card")){
                     require(['../views/card/'+component+'.vue'],resolve)
                 }
                 else if (component.startsWith("Store")){
                     require(['../views/store/'+component+'.vue'],resolve)
                 }
                  else if (component.startsWith("Staff")){
                     require(['../views/staff/'+component+'.vue'],resolve)
                 }
                 else if (component.startsWith("Work")){
                     require(['../views/work/'+component+'.vue'],resolve)
                 }
                 else if (component.startsWith("Pro")){
                     require(['../views/pro/'+component+'.vue'],resolve)
                 }
                 else if (component.startsWith("Client")){
                     require(['../views/client/'+component+'.vue'],resolve)
                 }
                 else if (component.startsWith("Cl")){
                     require(['../views/cl/'+component+'.vue'],resolve)
                 }else if(component.startsWith("Parts")){
                     require(['../views/parts/'+component+'.vue'],resolve)
                 }else if (component.startsWith("Outlets")){
                     require(['../views/outlets/'+component+'.vue'],resolve)
                 }else if (component.startsWith("Warehouse")){
                     require(['../views/warehouse/'+component+'.vue'],resolve)
                 }else if (component.startsWith("Fittings")){
                     require(['../views/fittings/'+component+'.vue'],resolve)
                 }else if (component.startsWith("Suppliers")){
                     require(['../views/suppliers/'+component+'.vue'],resolve)
                 }else if (component.startsWith("Group")){
                     require(['../views/group/'+component+'.vue'],resolve)
                 }
                 else if (component.startsWith("Member")){
                     require(['../views/member/'+component+'.vue'],resolve)
                 }
                 else {
                     require(['../views/'+component+'.vue'],resolve)
                 }

             },

         }
         console.log(['../views/'+component+'.vue'])
         fmtRoutes.push(fmRouter)
     }
     );
    return fmtRoutes;

}